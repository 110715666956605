import React, { useContext, useState, useEffect, useCallback } from "react"
import { graphql, useStaticQuery } from "gatsby"
import {
  DrawerFinancing,
  Header,
  Footer,
  CardModel,
  SEO,
  FinancingHeader,
  FilterSortHeader,
  HomeDesktopHeader,
  HideOnScroll,
  DCXButton,
  CashShortcutCard,
  CreditShortcutCard,
  AppraiseShortcutCard,
  CompareShortcutCard,
  InsuranceShortcutCard,
} from "../components"
import { Frame } from "../components/landing/Frame"
import {
  CarContext,
  KeyboardContext,
  SearchContext,
  UIContext,
} from "../context"
import {
  sortModelsHome,
  urlWithSession,
  capitalizeWords,
  getBestSellingModels,
} from "../utils"
import { navigate } from "gatsby"
import { Payment, CreditText } from "../types"
import { NoProductsImage } from "../assets"

const HomePage: React.FC = () => {
  /* Fetch all data */
  const {
    allModels: { nodes: allModels },
  } = useStaticQuery(query)
  const { isKeyboardOpen } = useContext(KeyboardContext)
  const appContext = useContext(CarContext)
  const {
    payment,
    modelsPricesStocks,
    priceOrder,
    setPaymentToCash,
    setPaymentToCredit,
  } = appContext
  const {
    resetSearch,
    activeModelSearch,
    searchResults,
    resetAll,
    areFiltersActive,
  } = useContext(SearchContext)
  const {
    openAppraiseDrawer,
    openInsuranceDrawer,
    openCreditVideo,
  } = useContext(UIContext)

  // Clear user search on unmounting
  useEffect(() => resetSearch, [resetSearch])

  // State to control header when hide-on-scroll
  const [hideableHeaderShowed, setHideableHeaderStatus] = useState<boolean>(
    true
  )
  const handleCompareShotcutPress = useCallback(() => {
    navigate(urlWithSession("/comparador"))
  }, [])

  const [mostSoldCars] = useState(() =>
    getBestSellingModels(allModels).slice(0, 3)
  )

  return (
    <>
      <SEO url="https://dercocenterx.cl/home"></SEO>
      <Header />
      <div className="layout-product-list">
        <div className={`left-bar ${isKeyboardOpen ? "smaller" : ""}`}>
          <DrawerFinancing buttonGroupOrientation="vertical" />
        </div>
        <div className="product-list">
          <HideOnScroll setState={setHideableHeaderStatus}>
            <FinancingHeader />
          </HideOnScroll>
          <FilterSortHeader
            topPosition={hideableHeaderShowed ? "second-place" : "first-place"}
            activeModelSearch={activeModelSearch}
          />
          <HomeDesktopHeader allModels={allModels} />
          <div
            className={`models-filtered ${
              searchResults.length === 0 || !areFiltersActive ? "hide" : ""
            }`}
          >
            <span>
              <span
                className="models-filtered__icon"
                role="img"
                aria-label="Ícono de auto"
              >
                🚗
              </span>
              {`Encontramos ${searchResults.length} modelo${
                searchResults.length > 1 ? "s " : " "
              }`}
            </span>
          </div>
          <div
            className={`models-filtered video-link ${
              !areFiltersActive &&
              searchResults.length &&
              payment !== Payment.cash
                ? ""
                : "hide"
            }`}
          >
            <span
              className="clickable"
              onClick={openCreditVideo}
              role="link"
              tabIndex="0"
              onKeyPress={openCreditVideo}
            >
              <span
                className="models-filtered__icon"
                role="img"
                aria-label="Ícono de auto"
              >
                💳
              </span>
              {`¿Qué es el ${
                payment === Payment.credit
                  ? capitalizeWords(CreditText.credit)
                  : capitalizeWords(CreditText.flexCredit)
              }? `}
              <span className="underlined">Te explicamos</span>
            </span>
          </div>
          <div className={`products ${!searchResults.length ? "empty" : ""}`}>
            <CashShortcutCard
              className={`${
                !searchResults.length || payment === Payment.cash
                  ? "hidden"
                  : ""
              }`}
              handleClick={setPaymentToCash}
            />
            <CreditShortcutCard
              className={`${
                !searchResults.length ||
                [Payment.credit, Payment.flexCredit].includes(payment)
                  ? "hidden"
                  : ""
              }`}
              handleClick={setPaymentToCredit}
            />
            <AppraiseShortcutCard
              className={`home-models-${searchResults.length} ${
                !searchResults.length ? "hidden" : ""
              }`}
              handleClick={openAppraiseDrawer}
            />
            <InsuranceShortcutCard
              className={`home-models-${searchResults.length} ${
                !searchResults.length ? "hidden" : ""
              }`}
              handleClick={openInsuranceDrawer}
            />
            <CompareShortcutCard
              className={`home-models-${searchResults.length} ${
                !searchResults.length ? "hidden" : ""
              }`}
              handleClick={handleCompareShotcutPress}
            />
            {(activeModelSearch && priceOrder === null
              ? searchResults
              : sortModelsHome(
                  searchResults,
                  payment,
                  modelsPricesStocks,
                  priceOrder ? -1 : 1
                )
            ).map((model, i) => (
              <CardModel
                key={i}
                model={model}
                appContext={appContext}
                terms={appContext.terms}
                ms={250 * i}
              />
            ))}
            <div className={`no-products`}>
              <Frame
                idx={0}
                image={NoProductsImage}
                desc="Ups! No encontramos autos que coincidan con tu búsqueda. Te podría interesar:"
              />
              <div className="no-products__most-sold">
                <h3>Los más vendidos</h3>
                <div className="most-sold__container">
                  {mostSoldCars.map((model, idx: number) => (
                    <CardModel
                      key={idx}
                      model={model}
                      appContext={appContext}
                      terms={appContext.terms}
                      ms={250}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer shortWidth />
    </>
  )
}

export const query = graphql`
  query FULL_QUERY_3 {
    allModels {
      nodes {
        ...ModelBasic
        ...Highlights
        ...Gallery
        ...HeroVideo
        ...RecommendVideo
        ...ColorGallery
        ...ModelStrings
        ...EngineSpecs
        ...DimensionSpecs
        ...PracticalData
        ...TechnicalData
        ...Versions
        ...ConfigOptions
      }
    }
  }
`

export default HomePage
